import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router'
import Download from '../components/download'
import { useLocation } from "@reach/router"
import { parse } from "query-string"


const DownloadPage = () => {

	const locationHook = useLocation()
	const searchParams = parse(locationHook.search)

	const adId = searchParams.ad || null

	return (
		<Router basepath="/:junk/download">
			<Download path="/:fileId/" adId={adId}/>
			<Download path="*" adId={adId}/>
		</Router>
	)
}

export default DownloadPage